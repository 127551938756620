import angular from "angular";

import {
    compactCircleInfoForFactuurControle,
    extendedCircleInfoForFactuurControle,
    getComputedExpressionValue,
} from "@skryv/bundle/customizations/components/premiumCalculationHelpers";

import template from "./FactuurcontroleSummary.html";
import "./FactuurcontroleSummary.scss";
import {
    convertFromPremiumCalculationResponse,
    convertToPremiumCalculationRequest
} from "../../../../util/premium-calculation-factory";
import {premieBerekeningForFinancieleControle} from "../../../store/actions";

const namespace = "wrp/components/factuur/FactuurcontroleSummary";

const CE_DOELGROEP = "doelgroep";
const CE_AANTAL_WOONEENHEDEN = "deeltal_aantal_wooneenheden";
const CE_UITSLUITEND_NACHTTARIEF = "untKlant";
const CE_SVK_SURPLUS = "int_svk_surplus";
const CE_PROPORTIONEEL_AANDEEL_TELLER = "proportioneelAandeelTeller";
const CE_PROPORTIONEEL_AANDEEL_NOEMER = "proportioneelAandeelNoemer";
const CE_RAMEN_EN_DEUREN_PRIVATIEF = "ramen_en_deuren_privatief";

angular.module(namespace, []).component("wrpFactuurcontroleSummary", {
    template,
    bindings: {
        documentModel: "<",
        withCalculations: "<",
    },
    controller: function ($timeout, $ngRedux) {
        "ngInject";

        const disconnect = $ngRedux.connect(mapStateToThis, {
            premieBerekeningForFinancieleControle,
        })(this);
        this.inProgress = false;

        let requestedCategoriesKeys = () => '';
        let administrativelyRejectedCategoriesKeys = () => '';
        let allApprovedCategoriesKeys = () => '';
        let allTechnicallyRejectedCategoriesKeys = () => '';
        let totalAmountValidated = () => 0;
        let totalAmountApproved = () => 0;
        let totalAmountRejected = () => 0;
        let totalAmountMax = () => 0;
        let totalPremiumValidated = () => 0;
        let totalPremiumMax = () => 0;
        let categoryAmountApproved = () => 0;
        let categoryAmountRejected = () => 0;
        let categoryAmountMax = () => 0;
        let categoryAmountMin = () => 0;
        let categoryPremium = () => 0;
        let categoryCalculation = (categoryKey) => ({});

        this.$onInit = () => {
            generatePremiumSummary(this);

            this.unsubscribe = $ngRedux.subscribe(() => {
                if (this.inProgress) return;
                fetchPremiumAndGenerateSummary(this);
            });
        };

        this.$onDestroy = () => {
            disconnect();
            if (this.unsubscribe) {
                this.unsubscribe();
            }
        };

        function fetchPremiumAndGenerateSummary(context) {
            context.inProgress = true;
            console.log(context.documentModel);
            context.premieBerekeningForFinancieleControle(convertToPremiumCalculationRequest(context.documentModel)).then((result) => {
                const premiumCalculationResponse = convertFromPremiumCalculationResponse(result.api.response.data);
                console.log(premiumCalculationResponse);
                requestedCategoriesKeys = premiumCalculationResponse.requestedCategoriesKeys;
                administrativelyRejectedCategoriesKeys = premiumCalculationResponse.administrativelyRejectedCategoriesKeys;
                allApprovedCategoriesKeys = premiumCalculationResponse.allApprovedCategoriesKeys;
                allTechnicallyRejectedCategoriesKeys = premiumCalculationResponse.allTechnicallyRejectedCategoriesKeys;
                totalAmountValidated = premiumCalculationResponse.totalAmountValidated;
                totalAmountApproved = premiumCalculationResponse.totalAmountApproved;
                totalAmountRejected = premiumCalculationResponse.totalAmountRejected;
                totalAmountMax = premiumCalculationResponse.totalAmountMax;
                totalPremiumValidated = premiumCalculationResponse.totalPremiumValidated;
                totalPremiumMax = premiumCalculationResponse.totalPremiumMax;
                categoryAmountApproved = premiumCalculationResponse.categoryAmountApproved;
                categoryAmountRejected = premiumCalculationResponse.categoryAmountRejected;
                categoryAmountMax = premiumCalculationResponse.categoryAmountMax;
                categoryAmountMin = premiumCalculationResponse.categoryAmountMin;
                categoryPremium = premiumCalculationResponse.categoryPremium;
                categoryCalculation = premiumCalculationResponse.categoryCalculation;
                generatePremiumSummary(context);
                context.inProgress = false;
            });
        }

        const generatePremiumSummary = (context) => {
            this.compactCircleInfo = compactCircleInfoForFactuurControle(
                requestedCategoriesKeys,
                allApprovedCategoriesKeys,
                allTechnicallyRejectedCategoriesKeys,
                administrativelyRejectedCategoriesKeys,
                totalAmountValidated,
                totalAmountMax,
                totalPremiumValidated,
                totalPremiumMax,
                categoryAmountApproved,
                categoryAmountMax,
                categoryAmountMin
            );
            this.extendedCircleInfo = extendedCircleInfoForFactuurControle({
                requestedCategoriesKeys,
                allApprovedCategoriesKeys,
                allTechnicallyRejectedCategoriesKeys,
                allAdministrativelyRejectedCategories: administrativelyRejectedCategoriesKeys,
                totalAmountValidated,
                totalAmountApproved,
                totalAmountRejected,
                totalAmountMax,
                totalPremiumValidated,
                totalPremiumMax,
                categoryAmountApproved,
                categoryAmountRejected,
                categoryAmountMax,
                categoryAmountMin,
                categoryPremium,
                categoryCalculation,
            });
            this.algemeneInfo = this.getAlgemeneInfo(context);
        }

        this.getAlgemeneInfo = (context) => {
            return {
                doelgroep: getComputedExpressionValue(
                    context.documentModel,
                    CE_DOELGROEP
                ),
                aantalWooneenheden: getComputedExpressionValue(
                    context.documentModel,
                    CE_AANTAL_WOONEENHEDEN
                ),
                uitsluitendNachttarief: getComputedExpressionValue(
                    context.documentModel,
                    CE_UITSLUITEND_NACHTTARIEF
                ),
                int_svk_surplus: getComputedExpressionValue(
                    context.documentModel,
                    CE_SVK_SURPLUS
                ),
                int_svk_surplus_ramen_en_deuren:
                    getComputedExpressionValue(context.documentModel, CE_SVK_SURPLUS) &&
                    !getComputedExpressionValue(
                        context.documentModel,
                        CE_RAMEN_EN_DEUREN_PRIVATIEF
                    ),
                proportioneel_aandeel_teller: getComputedExpressionValue(
                    context.documentModel,
                    CE_PROPORTIONEEL_AANDEEL_TELLER
                ),
                proportioneel_aandeel_noemer: getComputedExpressionValue(
                    context.documentModel,
                    CE_PROPORTIONEEL_AANDEEL_NOEMER
                ),
            };
        };

        function mapStateToThis(state) {
            return state;
        }
    },
});

export {template};
export default namespace;
