export const convertToPremiumCalculationRequest = (financialControlDocumentModel) => {
    const returnValue = {
        aangevraagdeCategorieen: selectedOptionsToList(financialControlDocumentModel.manipulator.computedExpressions.aangevraagde_categorieen),
        technischGoedgekeurdeCategorieen: selectedOptionsToList(financialControlDocumentModel.manipulator.computedExpressions.aangevraagde_categorieen_technisch_ok),
        technischAfgekeurdeCategorieen: selectedOptionsToList(financialControlDocumentModel.manipulator.computedExpressions.aangevraagde_categorieen_technisch_nok),
        administratiefAfgekeurdeCategorieen: selectedOptionsToList(financialControlDocumentModel.manipulator.computedExpressions.aangevraagde_categorieen_administratief_nok),
        doelgroep: "Doelgroep_1",
        facturen: financialControlDocumentModel.content.value.facturen.flatMap(factuur => factuur.factuurInhoud.gekeurde_waarden).map(gekeurde_waarden => ({
            gekeurdeWaarde: gekeurde_waarden.gekeurde_waarde,
            categorie: gekeurde_waarden.keuze_categorie.selectedOption,
            isGoedgekeurd: gekeurde_waarden.keuze_goed_of_afkeuren.selectedOption === 'goedkeuren'
        })),
    }
    console.log(returnValue);

    return returnValue;
}

export const convertFromPremiumCalculationResponse = (premiumCalculationResponse) => {
    const returnValue = {
        requestedCategoriesKeys: premiumCalculationResponse.requestedCategories,
        administrativelyRejectedCategoriesKeys: listToSelectedOptions(premiumCalculationResponse.administrativelyRejectedCategoriesKeys),
        allApprovedCategoriesKeys: listToSelectedOptions(premiumCalculationResponse.allApprovedCategoriesKeys),
        allTechnicallyRejectedCategoriesKeys: listToSelectedOptions(premiumCalculationResponse.allTechnicallyRejectedCategoriesKeys),
        totalAmountValidated: () => premiumCalculationResponse.totalAmountValidated,
        totalAmountApproved: () => premiumCalculationResponse.totalAmountApproved,
        totalAmountRejected: () => premiumCalculationResponse.totalAmountRejected,
        totalAmountMax: () => premiumCalculationResponse.totalAmountMax,
        totalPremiumValidated: () => premiumCalculationResponse.totalPremiumValidated,
        totalPremiumMax: () => premiumCalculationResponse.totalPremiumMax,
        categoryAmountApproved: (categoryKey) => premiumCalculationResponse.categoryAmountApproved[categoryKey],
        categoryAmountRejected: (categoryKey) => premiumCalculationResponse.categoryAmountRejected[categoryKey],
        categoryAmountMax: (categoryKey) => premiumCalculationResponse.categoryAmountMax[categoryKey],
        categoryAmountMin: (categoryKey) => premiumCalculationResponse.categoryAmountMin[categoryKey],
        categoryPremium: (categoryKey) => premiumCalculationResponse.categoryPremium[categoryKey],
        categoryCalculation: (categoryKey) => premiumCalculationResponse.categoryCalculations[categoryKey]
    }

    return returnValue;
}

export const selectedOptionsToList = (selectedOptions) => selectedOptions != null ? Object.keys(selectedOptions).filter(key => selectedOptions[key] === true) : [];
export const listToSelectedOptions = (list) => list != null ? list.reduce((obj, key) => {
    obj[key] = true;
    return obj;
}, {}) : {};
